<template>
  <div class="body">
    <Header />
    <main>

      <section id="work" class="work__
       top_section">
        <div class="container">
          <div class="section_header">
            <h2> كيف نعمل </h2>
            <!-- <p>نستلم تبرعاتك من موقعك ونوصلها لمحتاج يفرح بها</p> -->
          </div>
          <div class="kiswah_cards web">
            <div class="row">
              <!-- <div class="col-md-4">
                <div
                  class="c_card__ animate__animated second_"
                  v-inview:class="['animate__slideInLeft']"
                >
                  <div class="c_card_img">
                    <img
                      src="@/assets/img/1002.png"
                      alt=""
                    />
                  </div>
                  <div class="c_card_info">
                    <h3><span class="num">2</span> وصول مندوبنا</h3>
                    <p>مندوبينا متواجدين في جميع مناطق المملكة لسهولة الوصول لجميع العملاء خلال 24 ساعة فقط من تقديم الطلب</p>
                  </div>
                </div>

              </div>
              <div class="col-md-8">
                <div
                  class="c_card__ animate__animated flex_style"
                  v-inview:class="['animate__slideInUp']"
                >
                  <div class="c_card_img">
                    <img src="@/assets/img/1001.png" alt="" />
                  </div>
                  <div class="c_card_info">
                    <h3><span class="num">1</span> تسجيل الطلب</h3>
                    <p>قُم بتسجيل طلبك من خلال موقعنا أو أي من صفحاتنا أو حساباتنا على موقع التواصل الاجتماعي</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div
                      class="c_card__ animate__animated blue__"
                      v-inview:class="['animate__slideInUp']"
                    >
                      <div class="c_card_img">
                        <img src="@/assets/img/1004.png" alt="" />
                      </div>
                      <div class="c_card_info">
                        <h3><span class="num">4</span>توزيع الملابس</h3>
                        <p>توزيع الملابس الصالحة للاستخدام مرة أخرى و عادة تدوير الملابس الغير صالحة الى مواد خام داخل مراكز الشركة</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div
                      class="c_card__ animate__animated blue__"
                      v-inview:class="['animate__slideInRight']"
                    >
                      <div class="c_card_img">
                        <img src="@/assets/img/1003.png" alt="" />
                      </div>
                      <div class="c_card_info">
                        <h3><span class="num">3</span>تصنيف الملابس</h3>
                        <p>تصنيف الملابس في مراكز الشركة داخل المملكة لفرز الصالح و التالف من الملابس و إعادة تدوير التالف مره أخرى</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="col-md-4">
                <img src="@/assets/img/Poster3.png" class="img-fluid">
              </div>
              <div class="col-md-4">
                <img src="@/assets/img/Poster2.png" class="img-fluid">
                <img src="@/assets/img/Poster5.png" class="img-fluid mt-4">
              </div>
              <div class="col-md-4">
                <img src="@/assets/img/Poster1.png" class="img-fluid">
                <img src="@/assets/img/Poster4.png" class="img-fluid mt-4">
              </div>
            </div>
          </div>
          <div class="kiswah_cards mobile">
            <div class="row">
              <!-- <div class="col-sm-12">
                <div class="c_card__ animate__animated flex_style">
                  <div class="c_card_img">
                    <img src="@/assets/img/1001.png" alt="" />
                  </div>
                  <div class="c_card_info">
                    <h3><span class="num">1</span> تسجيل الطلب</h3>
                    <p>قُم بتسجيل طلبك من خلال موقعنا أو أي من صفحاتنا أو حساباتنا على موقع التواصل الاجتماعي</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="c_card__ animate__animated second_">
                  <div class="c_card_img">
                    <img
                      src="@/assets/img/1002.png"
                      alt=""
                    />
                  </div>
                  <div class="c_card_info">
                    <h3><span class="num">2</span>وصول مندوبنا</h3>
                    <p>مندوبينا متواجدين في جميع مناطق المملكة لسهولة الوصول لجميع العملاء خلال 24 ساعة فقط من تقديم الطلب</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="c_card__ animate__animated blue__">
                  <div class="c_card_img">
                    <img src="@/assets/img/1003.png" alt="" />
                  </div>
                  <div class="c_card_info">
                    <h3><span class="num">3</span>تصنيف الملابس</h3>
                    <p>تصنيف الملابس في مراكز الشركة داخل المملكة لفرز الصالح و التالف من الملابس و إعادة تدوير التالف مره أخرى</p>
                  </div>
                </div>
              </div>

              <div class="col-sm-12"> -->
                <!-- <div class="c_card__ animate__animated blue__">
                  <div class="c_card_img">
                    <img src="@/assets/img/1004.png" alt="" />
                  </div>
                  <div class="c_card_info">
                    <h3><span class="num">4</span>توزيع الملابس</h3>
                    <p>توزيع الملابس الصالحة للاستخدام مرة أخرى و عادة تدوير الملابس الغير صالحة الى مواد خام داخل مراكز الشركة</p>
                  </div>
                </div>
              </div> -->

              <div class="col-sm-12">
                <img src="@/assets/img/Poster1.png" class="img-fluid">
                <img src="@/assets/img/Poster2.png" class="img-fluid mt-4">
                <img src="@/assets/img/Poster3.png" class="img-fluid mt-4">
                <img src="@/assets/img/Poster4.png" class="img-fluid mt-4">
                <img src="@/assets/img/Poster5.png" class="img-fluid mt-4">
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="work1" class="work__
       top_section">
        <div class="container">
          <div class="section_header">
            <h2>كيفية عملنا مع الشركات</h2>
            <!-- <p>نستلم تبرعاتك من موقعك ونوصلها لمحتاج يفرح بها</p> -->
          </div>
          <div class="kiswah_cards web">
            <div class="row">
              <div class="col-md-4">
                <div
                  class="c_card__ animate__animated second_"
                  v-inview:class="['animate__slideInLeft']"
                >
                  <div class="c_card_img">
                    <img
                      src="@/assets/img/1006.png"
                      alt=""
                    />
                  </div>
                  <div class="c_card_info">
                    <h3><span class="num">2</span> توقيع الاتفاقية</h3>
                    <p>وضع بنود العقد المتفق عليه بين الجهتين</p>
                  </div>
                </div>

              </div>
              <div class="col-md-8">
                <div
                  class="c_card__ animate__animated flex_style"
                  v-inview:class="['animate__slideInUp']"
                >
                  <div class="c_card_img">
                    <img src="@/assets/img/1005.png" alt="" />
                  </div>
                  <div class="c_card_info">
                    <h3><span class="num">1</span>تحديد الأدوار</h3>
                    <p>التعريف بالشركة ودورها مع الجهات التي سنغطيها داخل المملكة</p>
                  </div>
                </div>
                <div class="row">
                  <!-- <div class="col-md-6">
                    <div
                      class="c_card__ animate__animated blue__"
                      v-inview:class="['animate__slideInUp']"
                    >
                      <div class="c_card_img">
                        <img src="@/assets/img/1008.png" alt="" />
                      </div>
                      <div class="c_card_info">
                        <h3><span class="num">4</span> التوزيع </h3>
                        <p>تستقبل المؤسسات أو الشركات المُتعاقد معها الملابس من جرين كلوزيت</p>
                      </div>
                    </div>
                  </div> -->
                  <div class="col-md-6">
                    <div
                      class="c_card__ animate__animated blue__"
                      v-inview:class="['animate__slideInRight']"
                    >
                      <div class="c_card_img">
                        <img src="@/assets/img/1007.png" alt="" />
                      </div>
                      <div class="c_card_info">
                        <h3><span class="num">3</span> التجميع والتصنيف</h3>
                        <p>يقوم فريق جرين كلوزيت بجمع الملابس الفائضة من العملاء وتصنيفها داخل مراكز الشركة</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="kiswah_cards mobile">
            <div class="row">
              <div class="col-sm-12">
                <div class="c_card__ animate__animated flex_style">
                  <div class="c_card_img">
                    <img src="@/assets/img/1005.png" alt="" />
                  </div>
                  <div class="c_card_info">
                    <h3><span class="num">1</span>تحديد الأدوار</h3>
                    <p>التعريف بالشركة ودورها مع الجهات التي سنغطيها داخل المملكة</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="c_card__ animate__animated second_">
                  <div class="c_card_img">
                    <img
                      src="@/assets/img/1006.png"
                      alt=""
                    />
                  </div>
                  <div class="c_card_info">
                    <h3><span class="num">2</span> توقيع الاتفاقية</h3>
                    <p>وضع بنود العقد المتفق عليه بين الجهتين</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="c_card__ animate__animated blue__">
                  <div class="c_card_img">
                    <img src="@/assets/img/1007.png" alt="" />
                  </div>
                  <div class="c_card_info">
                    <h3><span class="num">3</span> التجميع والتصنيف</h3>
                    <p>يقوم فريق جرين كلوزيت بجمع الملابس الفائضة من العملاء وتصنيفها داخل مراكز الشركة</p>
                  </div>
                </div>
              </div>

              <!-- <div class="col-sm-12">
                <div class="c_card__ animate__animated blue__">
                  <div class="c_card_img">
                    <img src="@/assets/img/1008.png" alt="" />
                  </div>
                  <div class="c_card_info">
                    <h3><span class="num">4</span>  التوزيع</h3>
                    <p>تستقبل المؤسسات أو الشركات المُتعاقد معها الملابس من جرين كلوزيت</p>
                  </div>
                </div>
              </div> -->

            </div>
          </div>
        </div>
      </section>


       <!-- ======= Features Section ======= -->
    <section id="features" class="features">
      <div class="container" data-aos="fade-up">

        <div class="row">
          <div class="col-lg-6 ar" data-aos="fade-left" data-aos-delay="100">
            <div class="icon-box mt-5" data-aos="zoom-in" data-aos-delay="150">
              <i class='bx bx-sun'></i>
              <h4>رؤيتنا</h4>
              <p>الريادة عالمياً بمعايير دولية في مجال إعادة تدوير الملابس المستعملة والوصول إلى مجتمع على مستوى عالي من الوعي والممارسات الإيجابية التي تكفل حماية البيئة من خطر النفايات النسيجية</p>
            </div>
            <div class="icon-box mt-5" data-aos="zoom-in" data-aos-delay="150">
              <i class='bx bx-rocket' ></i>
              <h4>مهمتنا </h4>
              <p>تعزيز الاستدامة من خلال جمع الملابس المستعملة وإعادة استخدامها مرة أخرى اوإعادة تدويرها لإنتاج مواد تصنيعية خام من جديد لتقليل نفايات الملابس والحفاظ على الطبيعة وحماية البيئة</p>
            </div>
            <div class="icon-box mt-5" data-aos="zoom-in" data-aos-delay="150">
              <i class='bx bx-target-lock'></i>
              <h4>هدفنا</h4>
              <p>نشر الوعي البيئي في المجتمع والمنظمات الربحية والغير ربحية للحفاظ على البيئة من خطر النفايات النسيجية</p>
            </div>
          </div>
          <div class="image col-lg-6" :style="{ backgroundImage: `url(${require('@/assets/img/about-us.jpeg')})` }"  data-aos="fade-right"></div>
        </div>

      </div>
    </section><!-- End Features Section -->



    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
export default {
  components: { Header, Footer },
  data() {
    return {
      images: [
        "image01.jpg",
        "C3383.00_00_32_015.Still001.jpg",
        "DSC04763.jpg",
        "DSC04775.jpg",
        "DSC05453.jpg",
        "DSC05460.jpg",
        "DSC05464.jpg",
        "DSC05481.jpg",
        "DSC08883.jpg",
        "DSC08897.jpg",
        "DSC09007.jpg",
        "DSC09035.jpg",
        "DSC09071.jpg",
        "DSC09080.jpg",
        "EQ0A1824.jpg",
        "EQ0A2148.jpg",
        "EQ0A2154.jpg",
        "EQ0A7661.jpg",
        "EQ0A7683.jpg",
        "IMG_1E0B55F0F5F7-46.jpeg",
        "IMG_4797.jpg",
        "IMG_4816.jpg",
        "IMG_4858.jpg",
        "IMG_5427.jpg",
        "IMG_5447.jpg",
        "IMG_5454.jpg",
        "‎⁨IMG_5458.jpg",
        "‎⁨IMG_5459.jpg",
        "‎IMG_5588.jpg",
      ],
      responsivePip: {
        0: { items: 1, nav: false },
        480: { items: 1 },
      },
      text: `
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
          richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
          brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
          tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
          assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
          wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
          vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic
          synth nesciunt you probably haven't heard of them accusamus labore VHS.
        `,
      news: [],
      charities: [],
    };
  },
  created() {
    window.fbq('track', 'home');
  },
  mounted() {
  },
  methods: {
    redirectTo(link) {
      window.open(link, "_blank");
    },
  },
  filters: {
    truncateString(str, num) {
      // If the length of str is less than or equal to num
      // just return str--don't truncate it.
      if (str.length <= num) {
        return str;
      }
      // Return str truncated with '...' concatenated to the end of str.
      return str.slice(0, num) + "...";
    },
  },
};
</script>

<style scoped>
.cancel {
  font-size: 16px;
  cursor: pointer;
  text-align: right;
  padding-top: 30px;
  padding-left: 10px;
}

.vm--modal .more__news {
  padding: 30px !important;
  text-align: right;
}
.more__news--image {
  width: 100%;
  object-fit: cover;
  /* max-width: 400px; */
}
</style>
